@import "../lib/mediaquery";
@import "../lib/variable";

.section-subtitle {
  font-weight: 600;
  letter-spacing: 0.22em;

  @include pc {
    font-size: 20px;
  }

  @include sp {
    text-align: center;
    font-size: 14px;
  }

  span {
    color: #fff;
    background-color: #000;
    padding: 0 4px;
  }
}

.section-title {
  font-weight: 600;
  letter-spacing: 0.18em;

  @include pc {
    font-size: 22px;
  }

  @include sp {
    text-align: center;
    font-size: 15px;
  }

  span {
    color: #ff6b00;

    @include pc {
      font-size: 32px;
    }

    @include sp {
      font-size: 20px;
    }
  }
}

.section-desc {
  letter-spacing: 0.18em;
  line-height: 2.1;

  @include pc {
    font-size: 14px;
  }

  @include sp {
    font-size: 13px;
  }
}

.section-point {
  &__title {
    text-align: center;
    font-weight: 600;
    clip-path: inset(0 100% 0 0);
    transition-duration: 1.5s;

    @include pc {
      font-size: 40px;
      letter-spacing: 0.16em;
      padding-bottom: 65px;
    }

    @include sp {
      font-size: 24px;
      letter-spacing: 0.1em;
      padding-bottom: 40px;
    }

    &.active {
      clip-path: inset(0);
    }

    span {
      color: #2ca5df;
    }

    background-repeat: no-repeat;
    background-position: bottom 0 center;

    > div {
      opacity: 0;
      transform: translateY(100%);
      transition-duration: 0.6s;

      &.active {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}



@import "../lib/mediaquery";
@import "../lib/variable";

.section-fv {
  position: relative;

  @include pc {
    padding: 40px 0 64px 0;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    @include pc {
      background-image: url("../assets/pages/home/section-fv-bg.svg");
      background-position: top 0 right 0;
      background-size: contain;
    }

    @include sp {
      
    }
  }

  &__copyright {
    position: absolute;
    writing-mode: vertical-rl;
    text-align: center;
    color: #b3b3b3;
    letter-spacing: 0.22em;
    font-size: 10px;

    @include pc {
      height: 100%;
      left: 2.65%;
    }

    @include sp {
      left: 13px;
      bottom: 15px;
    }
  }

  &__pop {
    position: absolute;

    @include pc {
      right: 5%;
      bottom: 3%;
    }

    @include sp {
      width: 286px;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%);
    }
  }

  &__slider {
    margin: 0 0 0 auto;

    @include pc {
      width: 84.58%;
      height: 922px;
    }

    @include sp {
      height: 216.41vw;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__logo {
    @include sp {
      width: 51.51%;
    }
  }

  &__title {
    position: absolute;

    @include pc {
      top: 10.5%;
      left: 9.3%;
      max-width: 539px;
      min-width: 420px;
      width: 28.07%;
    }

    @include sp {
      width: 84.61%;
      top: 2.4%;
      left: 50%;
      transform: translateX(-50%);
    }

    .title {
      display: block;
      width: 100%;
      clip-path: inset(0 100% 0 0);
      transition-duration: 1s;

      &.active {
        clip-path: inset(0);
      }

      @include sp {
        margin-top: 25px;
      }
    }
  }
}

.sns-list {
  position: absolute;
  display: flex;

  @include pc {
    flex-direction: column;
    align-items: center;
    bottom: 88px;
    left: 2.65%;
  }

  @include sp {
    top: 2.9%;
    z-index: 2;
    right: 22px;
  }

  li {
    &:nth-child(1n+2) {
      @include pc {
        margin-top: 36px;
      }

      @include sp {
        margin-left: 28px;
      }
    }
  }

  a {
    @include pc {
      opacity: 0.5;
    }

    &:hover {
      @include pc {
        opacity: 1;
      }
    }
  }
}

.section-anxiety {
  @include pc {
    margin-top: 98px;
  }

  @include sp {
    margin-top: 179px;
  }

  &__container {
    margin: 0 auto;

    @include pc {
      max-width: 1155px;
      padding: 0 32px;
    }

    @include sp {
      
    }
  }

  &__title {
    @include pc {
      margin-top: 34px;
    }

    @include sp {
      margin-top: 16px;
    }
  }

  &__text {
    @include pc {
      margin-top: 34px;
    }

    @include sp {
      width: 84.61%;
      margin: 25px auto 0 auto;
    }
  }

  &__content {
    @include pc {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    @include sp {
      margin-top: 40px;
    }
  }
}

.section-anxiety-item {
  position: relative;
  background-color: #e8f6fd;

  @include pc {
    width: 49.77%;
    padding: 105px 30px 98px 60px;
  }

  @include sp {
    width: 92.3%;
    padding: 45px 28px 40px 28px;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  &:nth-child(1) {
    @include pc {
      margin-top: 64px;
    }

    @include sp {
      margin: 0 0 0 auto;
    }

    &::before {
      background-image: url("../assets/pages/home/section-anxiety-item-icon-01.svg");

      @include pc {
        right: 30px;
        top: -16px;
        width: 80px;
        height: 152px;
      }

      @include sp {
        right: 30px;
        top: -8px;
        width: 58px;
        height: 110px;
      }
    }
  }

  &:nth-child(2) {
    @include sp {
      margin-top: 5px;
    }

    &::before {
      background-image: url("../assets/pages/home/section-anxiety-item-icon-02.svg");

      @include pc {
        top: -53px;
        right: 15px;
        width: 150px;
        height: 148px;
      }

      @include sp {
        top: 13px;
        left: 18px;
        width: 86px;
        height: 86px;
      }
    }

    .section-anxiety-item__title {
      @include sp {
        text-align: right;
      }
    }
  }

  &__title {
    letter-spacing: 0.16em;
    font-weight: 600;

    @include pc {
      font-size: 24px;
    }

    @include sp {
      font-size: 19px;
    }

    span {
      display: inline-block;
      background-repeat: no-repeat;
      background-position: left 0 center, right 0 center;
      background-image: url("../assets/pages/home/section-anxiety-item-title-left.svg"), url("../assets/pages/home/section-anxiety-item-title-right.svg");

      @include pc {
        padding: 8px 46px;
      }

      @include sp {
        padding: 8px 32px;
      }
    }
  }

  &__list {
    margin-top: 38px;
    letter-spacing: 0.18em;
    font-weight: 600;

    @include pc {
      font-size: 18px;
    }

    @include sp {
      font-size: 13px;
    }

    li {
      padding-left: 28px;
      background-image: url("../assets/pages/home/section-anxiety-item-list-check.svg");
      background-repeat: no-repeat;
      background-position: left 0 center;

      &:nth-child(1n+2) {
        margin-top: 8px;
      }
    }

    span {
      color: #ff6b00;
    }
  }
}

.section-box-content {
  position: relative;
  z-index: 1;

  &__container {
    margin: 0 auto;

    @include pc {
      max-width: 1155px;
      padding: 0 32px;
    }

    @include sp {
      width: 88%;
    }
  }

  &__content {
    position: relative;
    width: 100%;
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 3px;

    @include pc {
      padding: 56px 32px;
    }

    @include sp {
      padding: 30px 16px 34px 16px;
    }

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 10px;
      top: 10px;
      z-index: -1;
      background-color: #fff;
      border: 1px solid #000;
      border-radius: 3px;
    }
  }

  &__subtitle {
    display: block;
    max-width: 100%;
    margin: -15.4% auto 0 auto;
  }

  &__text {
    text-align: center;
    line-height: 2.23;

    @include pc {
      margin-top: 50px;
      font-size: 17px;
      letter-spacing: 0.18em;
    }

    @include sp {
      font-size: 13px;
      letter-spacing: 0.1em;
      margin-top: 22px;
    }

    span {
      color: #ff6b00;
    }
  }
}

.section-solution {
  @include pc {
    margin-top: 100px;
  }

  @include sp {
    margin-top: 52px;
  }

  &__title {
    text-align: center;
    letter-spacing: 0.14em;
    font-weight: 600;
    background-position: center bottom 0;

    @include pc {
      font-size: 25px;
      padding-bottom: 27px;
      background-image: url("../assets/pages/home/section-solution-title-border.svg");
    }

    @include sp {
      font-size: 14px;
      padding-bottom: 12px;
      background-image: url("../assets/pages/home/section-solution-title-border-sp.svg");
    }
  }

  .section-box-content__subtitle {
    @include sp {
      max-width: 105%;
      margin: -28.4% auto 0 auto;
    }
  }
}

.section-difference {

  &__title-area {
    background-repeat: no-repeat;
    background-image: url("../assets/pages/home/section-difference-bg.jpg");
    background-size: cover;
    background-position: top 0 center;

    @include pc {
      margin-top: -300px;
      padding-top: max(22.08vw, 423px);
      padding-bottom: 107px;
    }

    @include sp {
      padding-top: 40vw;
      margin-top: -100px;
      padding-bottom: 38px;
    }
  }

  &__title {
    @include pc {
      background-image: url("../assets/pages/home/section-difference-title-arrow.svg");
    }

    @include sp {
      background-image: url("../assets/pages/home/section-difference-title-arrow-sp.svg");
    }
  }

  &__content {
    @include pc {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
    }
  }
}

.section-difference-item {
  position: relative;

  @include pc {
    width: 33.17%;
  }

  &:nth-child(1n+2) {
    @include sp {
      margin-top: 3px;
    }
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.section-employment {
  @include pc {
    margin-top: 122px;
  }

  @include sp {
    margin-top: 48px;
  }

  &__title {
    @include pc {
      background-image: url("../assets/pages/home/section-employment-title-arrow.svg");
    }

    @include sp {
      background-image: url("../assets/pages/home/section-employment-title-arrow-sp.svg");
    }
  }

  &__container {
    position: relative;
    margin: 0 auto;
    z-index: 1;

    @include pc {
      max-width: 1296px;
      padding: 0 480px 0 32px;
      margin-top: 64px;
    }

    @include sp {
      margin-top: 50px;
    }
  }

  .employment-chart {
    @include pc {
      position: absolute;
      right: 0;
      top: -105px;
    }

    @media (min-width: 1366px) {

    }

    @media (max-width: 1365px) and (min-width: 769px) {
      width: 480px;
    }

    @include sp {
      display: block;
      margin: 10px auto 0 auto;
      max-width: 100vw;
      overflow: hidden;
    }

  }
}

.section-employment-work {
  position: relative;

  @include pc {
    margin-top: 78px;
    margin-bottom: 134px;
    padding: 64px 0 76px 0;
  }

  @include sp {
    background-color: #f7f7f7;
    margin-top: 30px;
    padding: 50px 0;
  }

  &::before {
    @include pc {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      background-color: #f7f7f7;
    }

    @media (min-width: 1366px) {
      // width: 63.69%;
      width: 52%;
    }

    @media (max-width: 1365px) and (min-width: 769px) {
      width: 80%;
    }
  }

  &__title {
    img {
      max-width: 100%;
    }
  }

  &__container {
    position: relative;
    margin: 0 auto;

    @include pc {
      max-width: 1296px;
      padding: 0 32px;
    }

    @include sp {
      width: 84.61%;
    }
  }

  &__text {
    margin-top: 14px;
    font-size: 15px;

    @include pc {
      letter-spacing: 0.22em;
    }

    @include sp {
      letter-spacing: 0.1em;
    }

    span {
      color: #ff6b00;
    }
  }

  &__data {
    display: block;

    @include pc {
      margin-top: -30px;
    }

    @include sp {
      margin-top: 10px;
      max-width: 100%;
    }
  }
}

.section-contact-link {
  position: relative;
  background-image: url("../assets/pages/home/section-contact-link-bg.jpg");
  background-size: cover;
  background-position: left 0 bottom 0;

  @include pc {
    height: 342px;
  }

  @include sp {
    padding: 34px 0;
  }

  &__container {
    @include sp {
      margin: 0 auto;
      width: 86.66%;
    }
  }

  &__title {
    position: relative;
    font-weight: 600;
    // background-size: contain;
    background-position: center;
    text-align: center;
    margin: 0 auto;
    clip-path: inset(0 100% 0 0);
    transition-duration: 0.8s;

    @include pc {
      width: 658px;
      height: 85px;
      padding-top: 10px;
      font-size: 28px;
      top: -40px;
      background-image: url("../assets/pages/home/section-contact-link-title-bg.svg");
      letter-spacing: 0.16em;
    }

    @include sp {
      width: 310px;
      height: 42px;
      padding-top: 4px;
      font-size: 14px;
      letter-spacing: 0.1em;
      background-image: url("../assets/pages/home/section-contact-link-title-bg-sp.svg");
    }

    &.active {
      clip-path: inset(0);

      div {
        opacity: 1;
      }
    }

    > div {
      transition-delay: 1s;
      transition-duration: 0.5s;
      opacity: 0;
    }

    span {
      color: #2ca5df;
    }

    // @include sp {
      
    // }

    // img {
    //   display: block;
    //   margin: 0 auto;
    // }
  }

  &__text {
    display: block;
    margin: 0 auto;

    text-align: center;
    color: #fff;
    font-weight: 600;

    @include pc {
      font-size: 20px;
      letter-spacing: 0.4em;
    }

    @include sp {
      font-size: 17px;
      letter-spacing: 0.05em;
      line-height: 1.8;
      margin-top: 10px;
    }

    span {
      padding-bottom: 4px;
      border-bottom: 3px solid #FFEE8B;
    }
  }

  &__btn-container {
    display: flex;
    justify-content: center;

    @include pc {
      align-items: flex-start;
      margin-top: 50px;
    }

    @include sp {
      align-items: center;
      flex-direction: column;
      margin-top: 38px;
    }
  }

  &__btn {
    display: block;

    @include pc {
      transition: transform 0.5s ease;
      transform-origin: center;
    }

    @include sp {
      max-width: 330px;
    }

    &:nth-child(1n+2) {
      @include pc {
        margin-left: 30px;
      }

      @include sp {
        margin-top: 12px;
      }
    }

    &:hover {
      @include pc {
        opacity: 1;
        transform: scale(1.08);
      }
    }

    img {
      width: 100%;

      @include sp {
        display: block;
        margin: 0 auto;
      }
    }
  }
}

.section-3-support {
  background-image: url("../assets/pages/home/section-3-support-title-bg.jpg");
  background-size: cover;
  background-position: center;

  @include pc {
    padding: 80px 0 170px 0;
  }

  @include sp {
    padding: 54px 0 46px 0;
  }

  &__title {
    color: #fff;

    @include pc {
      background-image: url("../assets/pages/home/section-3-support-title-arrow.svg");
    }

    @include sp {
      background-image: url("../assets/pages/home/section-3-support-title-arrow-sp.svg");
    }

    span {
      color: #fff !important;

      @include pc {
        font-size: 60px;
      }

      @include sp {
        font-size: 35px;
      }
    }
  }

  &__container {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    @include pc {
      margin-top: 75px;
    }

    @include sp {
      margin: 42px auto 0 auto;
      width: 81.02%;
    }
  }

  &__item {
    &:nth-child(1n+2) {
      @include pc {
        margin-left: 135px;
      }

      @include sp {
        margin-left: 28px;
      }
    }
  }
}

.seciton-support {
  @include sp {
    padding-top: 40px;
    background-color: #fff;
  }

  &__container {
    margin: 0 auto;

    @include pc {
      max-width: 1164px;
      padding: 0 96px 0 32px;
    }
  }

  &__inner {
    position: relative;

    @include pc {
      background-color: #fff;
      box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.3);
      padding-bottom: 88px;
      border: 1px solid #E3E3E3;
    }

    @media (min-width: 1366px) {
      padding-top: 170px;
    }

    @media (max-width: 1365px) and (min-width: 769px) {
      padding-top: 120px;
    }

    @include sp {
      box-shadow: 0 8px 8px rgba(0, 0, 0, 0.3);
      padding-bottom: 42px;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 12px;
      background: transparent linear-gradient(90deg, #035FB6CC 0%, #0271BECC 12%, #018CCACC 33%, #009ED3CC 54%, #00AAD8CC 76%, #00AEDACC 100%) 0% 0% no-repeat padding-box;
    }
  }

  &__num {
    position: absolute;

    @media (min-width: 1366px) {
      left: -73px;
      top: 48px;
    }

    @media (max-width: 1365px) and (min-width: 769px) {
      left: 20px;
      top: 28px;
    }

    @include sp {
      left: 0;
      top: 0;
    }
  }

  &__content {
    background-color: #fff;

    @include pc {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    @media (min-width: 1366px) {
      padding-left: 10.9%;
    }

    @media (max-width: 1365px) and (min-width: 769px) {
      padding-left: 32px;
      padding-right: 32px;
    }
  }

  &__detail {
    @include pc {
      width: 54.28%;
    }

    @include sp {
      position: relative;
      padding-top: 102px;
      padding-left: 7.69%;
      padding-right: 7.69%;
    }

    .wrap {
      margin-top: 52px;

      @include pc {
      }
    }

    .item:nth-child(1n+2) {
      @include pc {
        margin-top: 62px;
      }

      @include sp {
        margin-top: 50px;
      }
    }
  }

  &__title {
    font-size: 20px;
    letter-spacing: 0.22em;
    font-weight: 600;
  }

  &__text {
    position: relative;
    margin-top: 22px;
    padding-top: 18px;
    font-size: 16px;
    letter-spacing: 0.22em;
    line-height: 1.8;

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 34px;
      height: 2px;
      background: transparent linear-gradient(90deg, #035FB6CC 0%, #0271BECC 12%, #018CCACC 33%, #009ED3CC 54%, #00AAD8CC 76%, #00AEDACC 100%) 0% 0% no-repeat padding-box;
    }
  }

  &__image {
    position: relative;

    @media (min-width: 1366px) {
      right: -10%;
      width: 48.77%;
    }

    @media (max-width: 1365px) and (min-width: 769px) {
      width: 42%;
    }

    img {
      display: block;
      width: 100%;
      border-radius: 10px;
      filter: drop-shadow(0 0 12px rgba(0, 0, 0, 0.16));
    }
  }
}

.section-before-employment {
  background-image: url("../assets/pages/home/section-before-employment-bg.svg");
  background-size: 100% auto;
  background-position: bottom 0 center;

  @include pc {
    margin-top: -108px;
    padding-bottom: 155px;
  }

  @include sp {
    padding-bottom: 56px;
  }

  &__text {
    display: block;
    margin: 0 auto;

    @include pc {
      margin-top: 70px;
    }

    @include sp {
      margin-top: 40px;
    }
  }

  &__faq {
    margin: 0 auto;
    margin-top: 100px;
    padding: 0 32px;
    max-width: 1164px;
  }
}

.faq-accordion {
  border: 1px solid #1e314b;

  &:nth-child(1n+2) {
    margin-top: 60px;
  }

  &__panel {
    position: relative;
    color: #fff;
    background-color: #1e314b;
    font-weight: 600;
    cursor: pointer;
    background-image: url("../assets/pages/home/icon-plus-01.svg");
    letter-spacing: 0.1em;

    @include pc {
      font-size: 22px;
      padding: 20px 52px 20px 100px;
      background-position: right 28px center;
    }

    @include sp {
      font-size: 16px;
      padding: 45px 100px 22px 18px;
      background-size: 45px;
      background-position: right 20px center;
    }

    &.active {
      background-image: url("../assets/pages/home/icon-minus-01.svg");
    }

    small {
      font-weight: normal;

      @include pc {
        padding-left: 1em;
      }

      @include sp {
        margin-top: 0.5em;
        display: block;
      }
    }
  }

  &__icon {
    position: absolute;

    @include pc {
      left: -29px;
      top: -22px;
    }

    @include sp {
      width: 90px;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__content {
    display: none;
    background-color: #fff;

    @include pc {
      padding: 48px 32px;
    }

    @include sp {
      padding: 34px 16px;
    }
  }

  &__image {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
}

.section-timeline {
  @include pc {
    max-width: 810px;
    margin: 0 auto;
  }

  &__item {
    padding-left: 28px;

    @include pc {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      background-image: url("../assets/pages/home/timeline-arrow-pc.svg");
      min-height: 144px;
      background-position-y: 7px;
    }

    @include sp {
      position: relative;
      width: 86.54%;
      margin: 0 auto;
      background-image: url("../assets/pages/home/timeline-arrow-last-pc.svg");
      background-position-y: 7px;
    }

    &:nth-child(1n+2) {
      @include pc {
        margin-top: 20px;
      }

      @include sp {
        margin-top: 10px;
      }
    }

    &:nth-last-child(1n+2) {
      @include sp {
        padding-bottom: 40px;
      }

      &::before {
        @include sp {
          display: block;
          content: "";
          position: absolute;
          width: 1px;
          height: calc(100% - 18px);
          background-color: #1e314b;
          left: 5px;
          bottom: 0;
        }
      }

      &::after {
        @include sp {
          display: block;
          content: "";
          position: absolute;
          border: 4px solid transparent;
          border-top-color: #1e314b;
          bottom: -8px;
          left: 1px;
        }
      }
    }

    &:last-child {
      @include pc {
        background-image: url("../assets/pages/home/timeline-arrow-last-pc.svg");
        min-height: initial;
      }
    }
  }

  &__year {
    width: 88px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #036eb6;
    letter-spacing: 0.1em;
    font-weight: 600;
    border: 1px solid #036eb6;
    border-radius: 12px;
    line-height: 1;
    font-size: 14px;
    flex-shrink: 0;
  }

  &__content {
    @include pc {
      margin-left: 7.5%;
    }

    @include sp {
      margin-top: 18px;
    }
  }

  &__title {
    color: #2ca5df;
    font-weight: 600;

    @include pc {
      margin-top: -6px;
      font-size: 22px;
      letter-spacing: 0.22em;
    }

    @include sp {
      font-size: 20px;
      letter-spacing: 0.18em;
    }
  }

  &__text {
    font-size: 14px;

    @include pc {
      line-height: 1.8;
      margin-top: 26px;
      letter-spacing: 0.22em;
    }

    @include sp {
      letter-spacing: 0.1em;
      line-height: 1.5;
      margin-top: 20px;
    }

    small {
      display: block;
      text-align: right;
      margin-top: 10px;
    }
  }
}

.section-job-hunting {
  @include pc {
    margin-top: 148px;
    padding-bottom: 130px;
  }
}

.section-job-hunting-voice {
  @include pc {
    margin-top: 112px;
  }

  @include sp {
    background-color: #e8f6fd;
    padding: 36px 0 60px 0;
  }

  &__container {
    margin: 0 auto;

    @include pc {
      max-width: 1164px;
      padding: 0 32px;
    }

    @include sp {
      width: 84.61%;
    }
  }

  &__content {
    position: relative;

    @include pc {
      padding: 90px 72px 64px 72px;
      background-color: #e8f6fd;
    }
  }

  &__inner {
    @include sp {
      margin-top: 74px;
    }
  }

  &__title {

    @include pc {
      position: absolute;
      top: -32px;
      left: 50%;
      transform: translateX(-50%);
    }

    img {
      width: 242px;
      display: block;
      margin: 0 auto;
    }
  }

  &__text {
    line-height: 1.8;

    @include pc {
      font-size: 15px;
      letter-spacing: 0.22em;
    }

    @include sp {
      font-size: 14px;
      letter-spacing: 0.1em;
    }
  }

  &__support {
    background-color: #036eb6;

    @include pc {
      margin-top: 38px;
      padding: 26px 24px 30px 24px;
    }

    @include sp {
      margin-top: 28px;
      padding: 22px 14px;
    }

    ul {
      margin-top: 18px;
      font-size: 14px;
      color: #fff;
      line-height: 1.6;
      letter-spacing: 0.22em;

      li {
        min-height: 30px;
        padding-left: 46px;
        background-image: url("../assets/pages/home/icon-check.svg");

        @include pc {
          background-position: 0 center;
        }

        @include sp {
          background-position: left 0 top 0;
        }

        &:nth-child(1n+2) {
          margin-top: 20px;
        }
      }
    }
  }
}

.section-interview {
  @include pc {
    padding-top: 134px;
  }

  @include sp {
    margin-top: 66px;
    padding-bottom: 64px;
  }

  &__title {
    @include pc {
      background-image: url("../assets/pages/home/section-interview-title-arrow.svg");
    }

    @include sp {
      background-image: url("../assets/pages/home/section-interview-title-arrow-sp.svg");
    }
  }

  &__container {
    @include pc {
      margin-top: 70px;
    }

    @include sp {
      margin-top: 20px;
    }
  }
}

.section-interview-item {
  &:nth-child(1n+2) {
    @include pc {
      margin-top: 90px;
    }

    @include sp {
      margin-top: 20px;
    }
  }

  &__title02 {
    position: relative;
    border: 1px solid #707070;
    color: #2ca5df;
    background-color: #fff;
    font-weight: 600;
    font-size: 17px;
    z-index: 1;

    @include pc {
      letter-spacing: 0.22em;
      padding: 28px 42px;
    }

    @include sp {
      letter-spacing: 0.16em;
      padding: 25px;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      border: 11px solid transparent;
      border-right-color: #2CA5DF;
      border-bottom-color: #2CA5DF;
    }
  }

  &__title-area {
    position: relative;
    box-shadow: 10px 10px 14px rgba(0, 0, 0, 0.3);

    @include pc {
      height: 416px;
    }

    @media (min-width: 1366px) {
      width: 72.34%;
    }

    @media (max-width: 1365px) and (min-width: 769px) {
      width: 85%;
    }

    @include sp {
      width: 92.3%;
      height: 250px;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #fff;
      left: 0;
      top: 0;
      transition: transform 0.8s ease-out;
    }

    &--left {
      &::after {
        transform-origin: left;
      }
    }

    &--right {
      margin-left: auto;
      margin-right: 0;

      &::after {
        transform-origin: right;
      }
    }

    &.active::after {
      transform: scaleX(0);
    }
  }

  &__image {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__title {
    position: absolute;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2ca5df;
    line-height: 1.5;
    font-weight: 500;
    letter-spacing: 0.22em;

    @include pc {
      width: 394px;
      height: 156px;
      bottom: -40px;
      font-size: 16px;
    }

    @include sp {
      width: 245px;
      height: 105px;
      bottom: -56px;
      font-size: 13px;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #fff;
      left: 0;
      top: 0;
      transition: clip-path 0.8s ease-out;
    }

    &--left {
      @include pc {
        right: -106px;
        clip-path: polygon(7% 0, 100% 0%, 93% 100%, 0% 100%);
      }

      @include sp {
        right: -8.3%;
        clip-path: polygon(7% 0,100% 0,100% 100%,0 100%);
      }

      &::after {
        transform-origin: left;

        @include pc {
          clip-path: polygon(7% 0, 100% 0%, 93% 100%, 0% 100%);
        }

        @include sp {
          clip-path: polygon(7% 0,100% 0,100% 100%,0 100%);
        }
      }

      &.active::after {
        clip-path: polygon(7% 0, 7% 0, 0 100%, 0% 100%);
      }
    }

    &--right {
      @include pc {
        left: -106px;
        clip-path: polygon(7% 100%, 100% 100%, 93% 0, 0 0);
      }

      @include sp {
        left: -8.3%;
        clip-path: polygon(0% 100%,93% 100%,100% 0,0 0);
      }

      &::after {
        transform-origin: right;

        @include pc {
          clip-path: polygon(7% 100%, 100% 100%, 93% 0, 0 0);
        }

        @include sp {
          clip-path: polygon(0% 100%,93% 100%,100% 0,0 0);
        }
      }

      &.active::after {
        @include pc {
          clip-path: polygon(100% 100%, 100% 100%, 93% 0, 93% 0);
        }

        @include sp {
          clip-path: polygon(0% 100%,0 100%,0 0,0 0);
        }
      }
    }

    div {
      @include pc {
        width: 50%;
      }

      @include sp {
        width: 60%;
      }
    }
  }

  &__container {
    position: relative;
    margin: 0 auto;

    @include pc {
      // margin-top: 72px;
      max-width: 1044px;
      padding: 0 32px;
    }

    @include sp {
      // margin-top: 82px;
      width: 84.61%;
    }
  }

  &__icon {
    display: block;
    border: 7px solid #fff;

    @include pc {
      position: absolute;
      top: 60px;
      right: 32px;
      width: 294px;
      border-radius: 147px;
      z-index: 2;
    }

    @include sp {
      position: relative;
      max-width: 344px;
      width: 100%;
      border-radius: 172px;
      margin: 0 auto -22px auto;
    }
  }
}

.section-after-employment {
  background-image: url("../assets/pages/home/section-after-employment-bg.svg");
  background-size: cover;
  background-position: center;

  @include pc {
    margin-top: 112px;
    padding-bottom: 105px;
  }
}

.section-interview-faq {

  &:nth-of-type(1) {
    @include pc {
      width: calc(100% - 329px);
    }
  }

  &:nth-child(1n+2) {
    @include pc {
      margin-top: 56px;
    }

    @include sp {
      margin-top: 45px;
    }
  }

  &__title {
    font-weight: 600;
    background-image: url("../assets/pages/home/icon-q.svg");
    min-height: 62px;

    @include pc {
      padding-top: 15px;
      padding-left: 80px;
      background-position: left 0 top 0;
      font-size: 19px;
      letter-spacing: 0.22em;
    }

    @include sp {
      background-size: 38px auto;
      padding-left: 56px;
      background-position: left 0 top 10px;
      font-size: 18px;
      letter-spacing: 0.1em;
    }
  }

  &__content {
    @include pc {
      margin-top: 18px;
    }
  }

  &__text {
    font-size: 14px;
    font-weight: 300;

    @include pc {
      letter-spacing: 0.22em;
      line-height: 2;
    }

    @include sp {
      line-height: 1.8;
    }

    small {
      display: block;
      text-align: right;
      margin-top: 4px;
    }
  }
}

.section-faq {
  @include pc {
    padding-top: 126px;
    padding-bottom: 170px;
  }

  @include sp {
    padding-top: 58px;
    padding-bottom: 68px;
  }

  &__title {
    @include pc {
      background-image: url("../assets/pages/home/section-faq-title-arrow.svg");
    }

    @include sp {
      background-image: url("../assets/pages/home/section-faq-title-arrow-sp.svg");
    }
  }

  &__content {
    margin: 0 auto;

    @include pc {
      margin-top: 94px;
      max-width: 1164px;
      padding: 0 32px;
    }

    @include sp {
      margin-top: 50px;
      width: 84.61%;
    }
  }
}

.faq-content {
  &:nth-child(1n+2) {
    border-top: 1px solid #ccc;

    @include pc {
      margin-top: 45px;
      padding-top: 45px;
    }

    @include sp {
      padding-top: 20px;
      margin-top: 20px;
    }
  }

  &__panel {
    background-image: url("../assets/pages/home/icon-q.svg");

    @include pc {
      background-position: bottom 0 left 0;
    }

    @include sp {
      background-position: center left 0;
      background-size: 38px 36px;
    }
  }

  &__title {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding-bottom: 12px;
    min-height: 62px;
    letter-spacing: 0.22em;
    font-weight: 600;
    font-size: 17px;

    @include pc {
      padding-left: 76px;
    }

    @include sp {
      padding-left: 56px;
    }

    span {
      cursor: pointer;
      display: flex;
      width: 100%;
      align-items: center;
      min-height: 38px;
      background-image: url("../assets/pages/home/icon-plus-02.svg");
      background-position: right 0 bottom 0;

      @include pc {
        padding-right: 108px;
      }

      @include sp {
        padding-right: 85px;
      }

      &.active {
        background-image: url("../assets/pages/home/icon-minus-02.svg");
      }
    }
  }

  &__content {
    display: none;
  }

  &__text {
    font-size: 14px;
    line-height: 2.14;

    @include pc {
      padding-top: 34px;
      letter-spacing: 0.22em;
    }

    @include sp {
      letter-spacing: 0.1em;
    }
  }
}

.section-tel {
  position: relative;
  z-index: 1;

  @include pc {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__item {
    background-size: cover;
    background-position: center;

    @include pc {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 49.79%;
      height: 450px;
    }

    @include sp {
      padding-bottom: 30px;
    }

    &:nth-child(1) {
      background-image: url("../assets/pages/home/tel-bg-01.jpg");

      @include pc {
        margin-top: -56px;
      }
    }

    &:nth-child(2) {
      background-image: url("../assets/pages/home/tel-bg-02.jpg");

      @include sp {
        margin-top: 3px;
      }
    }
  }

  &__title {
    position: relative;
    background-color: #1e314b;
    color: #fff;
    font-size: 18px;
    letter-spacing: 0.13em;
    font-weight: 600;

    @include pc {
      text-align: center;
    }

    span {
      display: block;

      @include pc {
        padding: 10px;
      }

      @include sp {
        padding: 24px 35px;
      }

      &.right {
        @include pc {
          padding-right: 170px;
        }
      }
    }

    img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      @include pc {
        right: -25px;
      }

      @include sp {
        right: 8px;
      }

      &.image-01 {
        @include sp {
          width: 100px;
        }
      }

      &.image-02 {
        @include sp {
          width: 198px;
        }
      }
    }
  }

  &__text {
    text-align: center;
    font-size: 16px;
    margin-top: 18px;
    color: #fff;
    font-weight: 600;
    letter-spacing: 0.16em;
  }

  &__tel {
    display: flex;
    justify-content: flex-start;
    margin: 0 auto;

    @include pc {
      margin-top: 38px;
      align-items: flex-end;
    }

    @include sp {
      margin-top: 28px;
      max-width: 100%;
      flex-direction: column;
      align-items: center;
    }

    img {
      max-width: 100%;
    }

    span {
      color: #fff;
      letter-spacing: 0.1em;
      font-weight: 600;
      font-size: 16px;

      @include pc {
        padding-bottom: 10px;
        margin-left: 10px;
      }

      @include sp {
        margin-top: 10px;
      }
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border: 1px solid #fff;
    text-decoration: none;
    font-size: 12px;
    letter-spacing: 0.11em;
    margin: 0 auto;
    width: 178px;
    height: 32px;

    @include pc {
      margin-top: 38px;
    }

    @include sp {
      margin-top: 16px;
    }
  }

  &__time {
    margin-top: 20px;
    font-size: 14px;
    letter-spacing: 0.22em;
    text-align: center;

    span {
      display: inline-block;
      padding: 12px 18px;
      background-color: #e8f6fd;
    }
  }
}

.section-reception {
  background-image: url("../assets/pages/home/section-reception-bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @include pc {
    padding: 178px 0;
  }

  @include sp {
    padding: 44px 0 50px 0;
  }
}

.section-3-support-container {
  
}

.support-nav {
  position: fixed;
  top: 50%;
  right: 20px;
  z-index: 100;
  transform: translate(150px, -50%);
  transition: transform 0.2s linear;

  @include sp {
    display: none;
  }

  &.active {
    transform: translateY(-50%);
  }

  &__item {
    &:nth-child(1n+2) {
      margin-top: 65px;
    }
  }

  &__link {
    position: relative;
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 7px;
    background-color: #000;
    text-decoration: none;

    &::after {
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border: 1px solid #ff6b00;
      border-radius: 10px;
    }

    span {
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      display: none;
      white-space: nowrap;
      color: #ff6b00;
      font-size: 10px;
      letter-spacing: 0.2em;
      font-weight: 700;
      line-height: 1;

      strong {
        font-size: 21px;
      }
    }

    &.active {
      background-color: #ff6b00;

      &::after {
        content: "";
      }

      span {
        display: block;
      }
    }
  }
}

// .sp-fixed-contact {
//   position: fixed;
//   right: 0;
//   bottom: 0;
//   display: block;
//   z-index: 100;

//   img {
//     display: block;
//   }
// }

.fixed-content {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  width: 100%;
  transition: all 0.15s linear;
  transform: translateY(100%);

  &.active {
    transform: translate(0%);
  }

  a {
    display: block;

    &.line {
      width: 38.71%;
    }

    &.contact {
      width: 61.28%;
    }

    img {
      display: block;
      width: 100%;
    }
  }
}


.employment-chart {
  @include pc {
    width: 575px;
  }

  &__item {
    position: relative;

    @media (min-width: 1366px) {
      width: 378px;
      height: 378px;
    }


    @media (max-width: 1365px) and (min-width: 769px) {
      width: 316px;
      height: 316px;
    }

    @include sp {
      width: 270px;
      height: 270px;
      margin: 0 auto;
    }

    &:nth-child(1) {
      @include pc {
        margin: 0 0 0 auto;
      }
    }

    &:nth-child(2) {
      @include pc {
        margin: -5% auto 0 0;
      }

      @include sp {
        margin-top: 25px;
      }
    }
  }

  &__text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.footer {
  @include sp {
    padding-bottom: 76px;
  }
}

@import "../lib/mediaquery";
@import "../lib/variable";

.footer {
  background-color: #1e314b;

  @include pc {
    padding: 76px 0 62px 0;
  }

  @include sp {
    padding: 74px 0 38px 0;
  }

  &__container {
    margin: 0 auto;

    @include pc {
      max-width: 1162px;
      padding: 0 32px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  &__logo {
    display: block;

    @include sp {
      width: 250px;
      margin: 0 auto;
    }

    img {
      display: block;

      @include sp {
        width: 100%;
      }
    }
  }

  &__copyright {
    color: #fff;
    font-size: 10px;
    letter-spacing: 0.23em;

    @include pc {
      margin-top: 28px;
      text-align: right;
    }

    @include sp {
      text-align: center;
      margin-top: 58px;
    }
  }
}

.footer-nav {
  @include pc {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  @include sp {
    margin-top: 44px;
  }

  li {
    @include sp {
      text-align: center;
    }

    &:nth-child(1n+2) {
      @include pc {
        margin-left: 10px;
        padding-left: 10px;
        border-left: 1px solid #fff;
      }

      @include sp {
        margin-top: 12px;
      }
    }
  }

  a {
    text-decoration: none;
    color: #fff;
    font-size: 13px;
    letter-spacing: 0.22em;
  }
}




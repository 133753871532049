#lean_overlay {
	background: #000;
	cursor: pointer;
	display: none;
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0px;
	left: 0px;
	z-index: 9999;
}
.remodal {
	color: #000;
	display: none;
	margin: 0 auto;
	width: 96%;
	max-width: 1200px;
	max-height: 95%;
	background: #fff;
	overflow: auto;
}
.modal_area {
	padding: 10px;
}
.remodal h2.title {
	background-color: #a2bae0;
}
.remodal .title img {
	margin: 0 auto;
}
.modal_area.type01 .title img {
	padding: 29px 0;
	width: 421px;
}
.modal_area.type01 dl {
	display: table;
	margin: 0 auto;
	padding: 60px 0 20px;
	width: 1000px;
}
.modal_area.type01 dt {
	display: table-cell;
	vertical-align: middle;
	width: 216px;
}
.modal_area.type01 dd {
	display: table-cell;
	vertical-align: middle;
	padding-left: 55px;
	width: 784px;
}
.modal_area.type01 dd .image01 {
	width: 628px;
}
.modal_area.type01 dd .txt {
	color: #000;
	font-size: 16px;
	text-align: left;
	padding: 5px 0 10px;
}
.modal_area.type01 dd .image02 {
	width: 417px;
}
.remodal .remodal-cancel {
	background: none;
	display: block;
	width: 312px;
	margin: 40px auto;
	padding: 0;
	outline: none;
	border: none;
}
.modal_area.type02 h2.title img {
	padding: 28px 0;
	width: 581px;
}
.modal_area.type02 ul {
	margin: 33px auto 25px;
	width: 1090px;
}
.modal_area.type02 ul li {
	float: left;
}
.modal_area.type02 ul li.box_area {
	border: 1px solid #b5b5b6;
	color: #231815;
	padding: 30px 25px 25px;
	width: 318px;
	height: 234px;
}
.modal_area.type02 ul li.arrow {
	padding: 95.5px 21.5px;
}
.modal_area.type02 ul li.box_area h3.title {
	margin: 0 auto 25px;
	width: 122px;
}
.modal_area.type02 ul li.box_area .sub.title {
	font-size: 28px;
	font-weight: bold;
	margin-bottom: 10px;
}
.modal_area.type02 ul li.box_area .txt {
	text-align: left;
}
.modal_area.type02 .version {
	margin: 0 auto;
	text-align: left;
	width: 1090px;
}
.modal_area.type02 .version .title:before {
	content: url(../img/pc_flow_mark.png);
	position: absolute;
	left: -30px;
}
.modal_area.type02 .version .title {
	margin-left: 30px;
	padding-top: 20px;
	position: relative;
}
.modal_area.type02 .version .txt {
	padding-top: 10px;
	line-height: 1.8;
}
.modal_area.type02 .version.type01 .title {
	width: 107px;
}
.modal_area.type02 .version.type02 .title {
	width: 325px;
}

.remodal.ft {
	color: #000;
	text-align: left;
	padding: 30px 30px 0;
}
.remodal.ft h2.title {
	background: none;
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 20px;
}
.remodal.ft dl {
	margin-bottom: 30px;
}
.remodal.ft dt.title {
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 5px;
}
.remodal.ft .txt {
	line-height: 1.8;
}
.remodal.ft button {
	background-color: #ccc;
	border-radius: 5px;
	color: #000;
	font-size: 20px;
	font-weight: bold;
	line-height: 50px;
	text-align: center;
	margin-bottom: 50px;
	width: 200px;
}
.remodal.ft button:hover {
	cursor: pointer;
}
.remodal.ft dd li:before {
	content: "・"
}
.remodal.ft dd li {
	line-height: 1.8;
	padding-left: 14px;
	text-indent: -14px;
}

.remodal {
	font-size: 14px;

	@include sp {
		width: 90%;
	}
}

@include sp {
	.remodal.ft {
		padding: 20px 5% 0;
	}
	.remodal.ft h2.title {
		font-size: 12px;
		margin-bottom: 10px;
	}
	.remodal.ft button {
		border-radius: 3px;
		font-size: 12px;
		line-height: 30px;
		margin: 20px auto;
		width: 30%;
	}
	.remodal.ft dl {
		margin-bottom: 15px;
	}
	.remodal.ft dt.title {
		font-size: 11px;
		margin-bottom: 5px;
	}
	.remodal.ft .txt {
		font-size: 10px;
		line-height: 1.6;
	}
	.remodal.ft dd li {
		font-size: 10px;
		line-height: 1.6;
		padding-left: 10px;
		text-indent: -10px;
	}
	
	
}
@import "../lib/mediaquery";
@import "../lib/variable";

.section-inquiry {
  background-color: #e8f6fd;

  @include pc {
    padding: 120px 32px;
  }

  @include sp {
    padding: 48px 0;
  }

  &__container {
    margin: 0 auto;
    background-color: #fff;

    @include pc {
      padding: 115px 0 102px 0;
      width: 1094px;
    }

    @include sp {
      width: 92.3%;
      padding: 46px 0 92px 0;
    }
  }

  &__content {
    margin: 0 auto;

    @include pc {
      width: 81.62%;
    }

    @include sp {
      width: 77.22%;
    }
  }

  &__title {
    letter-spacing: 0.16em;
    font-weight: 600;
    text-align: center;
    background-position: bottom 0 center;

    @include pc {
      font-size: 33px;
      padding-bottom: 30px;
      background-image: url("../assets/pages/inquiry/title-arrow.svg");
    }

    @include sp {
      font-size: 20px;
      padding-bottom: 20px;
      background-image: url("../assets/pages/inquiry/title-arrow-sp.svg");
    }
  }

  &__text {
    letter-spacing: 0.1em;
    line-height: 2;
    font-size: 13px;

    @include pc {
      text-align: center;
      margin-top: 44px;
    }

    @include sp {
      margin-top: 40px;
    }
  }
}

.inquiry-form {
  @include pc {
    margin-top: 60px;
  }

  @include sp {
    margin-top: 50px;
  }

  &__error-text {
    letter-spacing: 0.12em;
    line-height: 1.8;
    color: #BF0D23;
    text-align: center;

    @include pc {
      font-size: 16px;
    }

    @include sp {
      font-size: 13px;
    }
  }

  &__value {
    font-size: 14px;
    letter-spacing: 0.22em;
    line-height: 2;
  }

  &__item {

    @include pc {
      display: flex;
    }

    &:nth-child(1n+2) {
      border-top: 1px solid #BABABA;
      padding-top: 40px;
      margin-top: 40px;
    }

    &:last-of-type {
      border-bottom: 1px solid #BABABA;
      padding-bottom: 40px;
    }
  }

  &__label {
    font-size: 14px;
    font-weight: 600;

    @include pc {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 176px;
      letter-spacing: 0.22em;
    }

    @include sp {
      letter-spacing: 0.18em;
    }

    .required {
      font-size: 12px;
      color: #ff6b00;
    }
  }

  &__content {
    @include pc {
      width: calc(100% - 202px);
      margin-left: 26px;
    }

    @include sp {
      margin-top: 14px;
    }

    .input {
      display: block;
      min-height: 40px;
      padding: 12px 18px;
      font-size: 14px;
      letter-spacing: 0.22em;
      background-color: #f7f7f7;
    }


    input {
      &::placeholder {
        color: #bababa;
      }
    }

    > input {
      width: 100%;
    }

    select {
      background-image: url("../assets/pages/inquiry/select-arrow.svg");
    }

    > select {
      width: 100%;
      background-position: center right 24px;
    }

    textarea {
      width: 100%;
      height: 176px;
    }
  }

  .inquiry-form-select-days {
    &__item {
      @include pc {
        display: flex;
        align-items: center;
      }

      &:nth-child(1n+2) {
        margin-top: 20px;
      }
    }

    &__label {
      letter-spacing: 0.22em;
      font-size: 14px;
    }

    &__input {
      @include pc {
        margin-left: 30px;
      }

      @include sp {
        margin-top: 10px;
      }

      input {
        @include sp {
          width: 100%;
        }
      }
    }

    &__select {
      @include pc {
        margin-left: 12px;
      }

      @include sp {
        margin-top: 14px;
      }

      select {
        background-position: center right 14px;

        @include sp {
          width: 100%;
        }
      }
    }

    > p {
      font-size: 12px;
      letter-spacing: 0.22em;
      margin-top: 15px;
    }
  }

  &__btn {
    margin-top: 60px;

    @include pc {
      display: flex;
      justify-content: center;
    }

    a {
      @include sp {
        display: block;
        width: 100%;
      }

      img {
        display: block;

        @include sp {
          width: 100%;
        }
      }
    }

    button {
      @include sp {
        display: block;
        width: 100%;
      }

      &:nth-child(1n+2) {
        @include pc {
          margin-left: 40px;
        }

        @include sp {
          margin-top: 30px;
        }
      }

      img {
        display: block;

        @include sp {
          width: 100%;
        }
      }
    }
  }
}

.ui-widget {
  font-size: 1.4rem !important;
}



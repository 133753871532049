@import "../lib/mediaquery";  
@import "../lib/variable";

.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #fff;
  z-index: 100;

  @include pc {
    padding: 20px 0;
  }

  @include sp {
    padding: 10px 0;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;

    @include pc {
      width: 92.18%;
    }

    @include sp {
      width: 95%;
    }
  }

  &__sptel {
    display: flex;

    a {
      display: block;
      &:nth-child(1n+2) {
        margin-left: 4px;
      }
    }
  }

  &__logo {
    @include pc {
      width: 14.86%;
    }

    img {
      @include pc {
        width: 100%;
      }
    }
  }

  &__text {
    @include pc {
      margin: 0 auto 0 35px;
      width: 40.78%;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @include pc {
      width: 31.76%;
    }
  }

  &__btn {
    display: block;

    &:nth-child(1n+2) {
      @include pc {
        margin-left: 4%;
      }
    }

    &.line {
      @include pc {
        width: 38%;
      }
    }

    &.contact {
      @include pc {
        width: 58.2%;
      }
    }

    img {
      display: block;

      @include pc {
        width: 100%;
      }
    }
  }
}

main {
  @include pc {
    margin-top: 110px;
  }

  @include sp {
    margin-top: 53px;
  }
}
